import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import seoConfig from '../seoConfig';
import routes from '../Routes';
import { mobileThreshold } from '../components/home/v3/styledComponents';

import { helpScoutContactForm, googleTagManagerScript, googleTagManagerNoScript } from '../vendors';
import withCustomerContext from '../withCustomerContext';
import ScreenSaver from '../components/ScreenSaver';

import '../polyfill/trimStart';
import '../assets/flaticon/flaticon.css';

const disableScripts = () => typeof window !== 'undefined'
  && (window.location.pathname === '/styleguide' || window.location.pathname === '/nos-tarifs-app');

const disableScriptsExceptOnProd = () => typeof window === 'undefined' || !(process.env.GATSBY_ENV === 'PROD');

function isMobile() {
  return typeof window !== 'undefined' ? window.innerWidth < mobileThreshold : true;
}

const getRoute = routeSlug => routes[routeSlug];

const getTitle = (routeSlug) => {
  if (!routeSlug) return seoConfig.title;
  const { seo = {} } = getRoute(routeSlug);
  return seo.title || seoConfig.title;
};

const getDescription = (routeSlug) => {
  if (!routeSlug) return seoConfig.description;
  const { seo = {} } = getRoute(routeSlug);
  return seo.description || seoConfig.description;
};

const getSeo = (routeSlug) => {
  if (!routeSlug) return seoConfig.description;
  const { seo = {} } = getRoute(routeSlug);
  return seo || seoConfig;
};

const getUrl = (routeSlug) => {
  if (!routeSlug) return seoConfig.baseUrl;
  const { url } = getRoute(routeSlug);
  return `${seoConfig.baseUrl}${url}`;
};

const createPostsScriptMarkup = (postSeo) => {
  const {
    title, description, mainEntityOfPageId, image, datePublished, dateModified,
    authorName, publisherName, publisherLogoUrl,
  } = postSeo;
  const markup = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${seoConfig.baseUrl}${mainEntityOfPageId}`,
    },
    headline: title,
    image: image && image.map(img => `${seoConfig.baseUrl}${img}`),
    datePublished,
    dateModified,
    author: {
      '@type': 'Person',
      name: authorName,
    },
    publisher: {
      '@type': 'Organization',
      name: publisherName,
      logo: {
        '@type': 'ImageObject',
        url: `${seoConfig.baseUrl}${publisherLogoUrl}`,
      },
    },
    description,
  };
  return JSON.stringify(markup);
};

// TODO remove this temporary code to force to unregister SW
// SW was installed on users devices after a failed attempt to setup gatsby-offline-plugin
if (typeof navigator !== 'undefined' && navigator.serviceWorker && navigator.serviceWorker.getRegistrations) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.map(registration => registration.unregister());
  });
}

export const AppContext = createContext({
  isMobile: true,
});

function renderSentry() {
  return (
    <Helmet>
      <script
        src="https://browser.sentry-cdn.com/5.12.1/bundle.min.js"
        integrity="sha384-y+an4eARFKvjzOivf/Z7JtMJhaN6b+lLQ5oFbBbUwZNNVir39cYtkjW1r6Xjbxg3"
        crossOrigin="anonymous"
        onLoad="Sentry.init({ dsn: 'https://04d154c0fb284855a70b80991c14f2b5@sentry.io/158955' })"
      />
      <script>{googleTagManagerScript}</script>
    </Helmet>
  );
}

class Layout extends React.Component {
  constructor() {
    super();
    this.state = { isMobile: true };
    this.startMouseTimeout();
  }

  componentDidMount() {
    const { customerContext: { fetchCustomer } } = this.props;
    fetchCustomer();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('click', this.handleMouseMove);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('click', this.handleMouseMove);
  }

  handleResize = () => {
    this.setState({ isMobile: isMobile() });
  }

  startMouseTimeout = () => {
    const idletime = 30; // time (in seconds) before launching the screensaver
    this.mousetimeout = setTimeout(() => {
      this.setState({ showScreensaver: true });
    }, 1000 * idletime);
  }

  handleMouseMove = () => {
    clearTimeout(this.mousetimeout);
    if (this.state.showScreensaver) {
      this.setState({ showScreensaver: false });
    }

    this.startMouseTimeout();
  }

  renderScreenSaver() {
    if (!this.state.showScreensaver) return null;
    return (<ScreenSaver />);
  }

  render() {
    const { children, routeSlug } = this.props;
    const seo = getSeo(routeSlug);
    const appContext = { isMobile: this.state.isMobile };
    return (
      <div>
        <Helmet
          title={getTitle(routeSlug)}
          meta={[{ name: 'description', content: getDescription(routeSlug) }]}
          link={[{ rel: 'canonical', href: getUrl(routeSlug) }]}
        />

        <Helmet>
          <html lang="fr" />
        </Helmet>

        {disableScripts()
          ? null
          : (
            <Helmet>
              <script>{helpScoutContactForm}</script>
            </Helmet>
          )
        }

        {/* enable Sentry and GTM only on prod */}
        {disableScriptsExceptOnProd()
          ? null
          : renderSentry()
        }

        {disableScriptsExceptOnProd()
          ? <div />
          : <div dangerouslySetInnerHTML={{ __html: googleTagManagerNoScript }} />
        }

        {seo.datePublished && <script type="application/ld+json">{createPostsScriptMarkup(seo)}</script>}

        {this.renderScreenSaver()}
        <AppContext.Provider value={appContext}>
          {children}
        </AppContext.Provider>
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  routeSlug: PropTypes.string,
  customerContext: PropTypes.shape({
    fetchCustomer: PropTypes.func,
  }).isRequired,
};

Layout.defaultProps = {
  children: null,
  routeSlug: null,
};

export default withCustomerContext(Layout);
