import React from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import withCustomerContext from '../../withCustomerContext';
import withOrderContext from '../../withOrderContext';
import routesMap from '../../Routes';
import { removeToken } from '../../services/api';
import DropdownList from './DropdownList';
import MobileMenu from './v3/MobileMenu';
import OutInSideClick from './v2/OutInSideClick';
import {
  margins, colors, fontSizes, mobileThresholdPixels, Row,
} from './v2/styledComponents';
import PopupLogin from '../MyAccount/PopupLogin';

import dropdownArrow from '../../assets/arrow-dropdown-list.svg';
import logoTilli from '../../assets/logos/tilli.navy.svg';
import menuIcon from '../../assets/menu.svg';
import closeIcon from '../../assets/menuClose.svg';
import happyIcon from '../../assets/home/happy.png';
import CTALink from '../CTALink';
import { Button as StyledButton } from './v3/styledComponents';
import { getServicesList, partnersList, inspirationsList } from './menuData';

const BarContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: ${({ hasBanner }) => hasBanner ? '130' : '70'}px;
  z-index: 3;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  @media (max-width: ${mobileThresholdPixels}) {
    background-color: ${colors.white};
    height: ${({ hasBanner }) => hasBanner ? 'unset' : '43px'};
    border-bottom-width: 0px;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100vw;
  margin: 27px 45px 20px 122px;
  display: flex;
  align-items: center;
  ${({ hasBanner }) => hasBanner && 'height: calc(70px - 27px - 20px);'}
`;

const StyledRow = styled(Row)`
  width: calc(100vw - 46px);
  margin: 13px 23px;
  align-items: center;
  height: 17px;
`;

const TextLink = styled.div`
  width: max-content;
  color: ${({ isYellow }) => isYellow ? colors.yellow : colors.navy};
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  padding: 0px 76px 0px 0px;
  ${props => props.right && `padding: 0px 0px 0px ${margins.m};`};
  ${props => props.middle && 'padding: 0px'};
  flex: 1;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 5px 0px 0px;
    color: ${colors.navy};
    font-size: ${fontSizes.s};
    text-align: center;
    &:hover {
      opacity: 1;
    }
  }
`;

const LinkContainer = styled.div`
  text-align: center;
  ${props => props.logo && 'margin: auto;'}

  @media (max-width: ${mobileThresholdPixels}) {
    ${props => props.logo && 'margin: 0px;'}
    margin-top: 0px;
  }
`;

const LeftPart = styled.div`
  position: absolute;
  left: 0px;
  display: flex;
  flex-direction: row;
`;

const RightPart = styled.div`
  position: absolute;
  right: 15px;
  display: flex;
  flex-direction: row;
`;

const LogoImg = styled.img`
  width: 160px;
`;

const MenuIcon = styled.img`
  width: 22px;
`;

const Img = styled.img`
  padding-bottom: 1px;
  margin-left: 10px;
  ${props => props.rotate && 'transform: rotate(180deg)'};
`;

const StyledLink = styled(Link)`
  flex: 1;
  text-align: center;
  text-decoration: none;
`;

const Banner = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  ${({ center }) => center && 'justify-content: center;'}
  align-items: center;
  background-color: ${colors.lightGrey};
  padding-top: ${({ center }) => center ? 26 : 20}px;
  padding-bottom: 20px;
  @media (max-width: ${mobileThresholdPixels}) {
    padding-top: ${({ center }) => center ? 10 : 6}px;
    padding-bottom: 6px;
    ${({ center }) => !center && 'flex-direction: column;'}
  }
`;

const GiftBanner = styled(Banner)`
  top: 8px;
  @media (max-width: ${mobileThresholdPixels}) {
    padding-left: 10px;
    flex-direction: row;
  }
`;

const BannerText = styled.div`
  color: ${colors.navy};
  font-family: Roboto;
  font-weight: 500;
  font-size: ${fontSizes.s};
  text-align: center;
  text-transform: uppercase;
`;

const GiftBannerText = styled.div`
  color: ${colors.navy};
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 18px;
  flex-grow: 1;
  text-align: center;
  min-width: 100vw;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 15px;
    min-width: unset;
  }
`;

const Icon = styled.img`
  width: 19px;
  height: 19px;
  margin-left: 5px;
  margin-bottom: -3px;
`;

const Button = styled(StyledButton)`
  min-width: 86px;
  min-height: 35px;
  position: absolute;
  left: 85vw;
  font-size: 15px;
  line-height: 19px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
    align-self: center;
    min-width: 86px;
    min-height: 35px;
    position: unset;
  }
`;

const GiftButton = styled(Button)`
  position: static;
`;

const A = styled.a`
  position: relative;
  right: 10vw;
  @media (max-width: ${mobileThresholdPixels}) {
    align-self: center;
    position: unset;
  }
`;

const renderLeftPart = () => (
  <LeftPart>
    <LinkContainer logo>
      <StyledLink to={routesMap.HomePage.url}>
        <TextLink middle>
          <LogoImg src={logoTilli} alt={'Logo du service de couturière Tilli '} />
        </TextLink>
      </StyledLink>
    </LinkContainer>
  </LeftPart>
);

const renderCTA = () => (
  <LinkContainer>
    <CTALink fromInteraction="MenuBar">
      <TextLink isYellow>{'Prendre rendez-vous'}</TextLink>
    </CTALink>
  </LinkContainer>
);

class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    const { isLoginPopupOpened } = this.props;
    this.state = {
      displayServicesList: false,
      displayPartnersList: false,
      displayMyAccountList: false,
      displayMobileMenuList: false,
      isLoginPopupOpened,
    };
    this.setIsLoginPopupOpened = this.setIsLoginPopupOpened.bind(this);
    this.getMyAccountList = this.getMyAccountList.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isLoginPopupOpened } = this.props;
    if (isLoginPopupOpened !== prevProps.isLoginPopupOpened) {
      this.setIsLoginPopupOpened(isLoginPopupOpened);
    }
    return null;
  }

  setIsLoginPopupOpened() {
    this.setState({ isLoginPopupOpened: !this.state.isLoginPopupOpened });
  }

  getMyAccountList() {
    const { customerContext: { setSelectedOrder, initializeCustomerContext } } = this.props;
    return ([
      { text: 'Récapitulatif', url: routesMap.MyAccount.url },
      { text: 'Mes informations', url: routesMap.MyInfos.url },
      { text: 'Mes adresses & paiements', url: routesMap.MyAddressesAndPayments.url },
      {
        text: 'Mes commandes',
        url: routesMap.MyOrders.url,
        onClick: () => {
          this.setState({ displayMyAccountList: false });
          setSelectedOrder(undefined);
        },
      },
      {
        text: 'Déconnexion',
        url: routesMap.HomePage.url,
        onClick: () => {
          initializeCustomerContext();
          removeToken();
        },
      },
    ]);
  }

  toggleMobileMenuList() {
    this.setState({ displayMobileMenuList: !this.state.displayMobileMenuList });
  }

  renderDesktop(hasBanner) {
    const { displayMyAccountList } = this.state;
    const { customerContext: { customer }, orderContext: { getOrderRouteAndEvent } } = this.props;
    const { orderRoute, restart } = getOrderRouteAndEvent();
    const servicesList = getServicesList(orderRoute, restart);
    return (
      <Content hasBanner={hasBanner}>
        {renderLeftPart()}

        <RightPart>
          <LinkContainer>
            <OutInSideClick handleClickOutside={() => this.setState({ displayServicesList: false })} >
              <div>
                <DropdownList isDisplayed={!!this.state.displayServicesList} dropdownlist={servicesList} />
                <TextLink
                  onMouseDown={() => this.setState({ displayServicesList: !this.state.displayServicesList })}
                >
                  {'Le service'}
                  <Img
                    src={dropdownArrow}
                    rotate={this.state.displayServicesList ? 'true' : undefined}
                    alt="Menu déroulant"
                  />
                </TextLink>
              </div>
            </OutInSideClick>
          </LinkContainer>
          <LinkContainer>
            <StyledLink to={routesMap.PricingsPage.url}>
              <TextLink>{'Grille des tarifs'}</TextLink>
            </StyledLink>
          </LinkContainer>
          <LinkContainer>
            <OutInSideClick handleClickOutside={() => this.setState({ displayPartnersList: false })} >
              <div>
                <DropdownList isDisplayed={!!this.state.displayPartnersList} dropdownlist={partnersList} />
                <TextLink
                  onMouseDown={() => this.setState({ displayPartnersList: !this.state.displayPartnersList })}
                >
                  {'Collabs'}
                  <Img
                    src={dropdownArrow}
                    rotate={this.state.displayPartnersList ? 'true' : undefined}
                    alt="Les partenaires Tilly"
                  />
                </TextLink>
              </div>
            </OutInSideClick>
          </LinkContainer>
          <LinkContainer>
            <OutInSideClick handleClickOutside={() => this.setState({ displayInspirationsList: false })} >
              <div>
                <DropdownList isDisplayed={!!this.state.displayInspirationsList} dropdownlist={inspirationsList} />
                <TextLink
                  onMouseDown={() => this.setState({ displayInspirationsList: !this.state.displayInspirationsList })}
                >
                  {'Mode Durable'}
                  <Img
                    src={dropdownArrow}
                    rotate={this.state.displayInspirationsList ? 'true' : undefined}
                    alt="Nos inspirations upcycling retouche transformation broderie"
                  />
                </TextLink>
              </div>
            </OutInSideClick>
          </LinkContainer>
          {(!customer || customer.isShadowAccount) &&
            <LinkContainer>
              <TextLink onClick={() => this.setIsLoginPopupOpened(true)}>{'Connexion'}</TextLink>
            </LinkContainer>
          }
          {!!customer && !customer.isShadowAccount &&
            <LinkContainer>
              <OutInSideClick handleClickOutside={() => this.setState({ displayMyAccountList: false })} >
                <div>
                  <DropdownList
                    isDisplayed={!!displayMyAccountList}
                    dropdownlist={this.getMyAccountList()}
                  />
                  <TextLink
                    onMouseDown={() => this.setState({ displayMyAccountList: !displayMyAccountList })}
                  >
                    Mon compte
                    <Img
                      src={dropdownArrow}
                      rotate={this.state.displayPartnersList ? 'true' : undefined}
                      alt="Menu déroulant Tilly"
                    />
                  </TextLink>
                </div>
              </OutInSideClick>
            </LinkContainer>
          }
          {renderCTA()}
        </RightPart>
      </Content>
    );
  }

  renderMobile() {
    return (
      <StyledRow justifyContent={'space-between'}>
        <LinkContainer logo>
          <StyledLink to={routesMap.HomePage.url}>
            <TextLink>
              <LogoImg src={logoTilli} alt={'Logo du service de couturière Tilli '} />
            </TextLink>
          </StyledLink>
        </LinkContainer>
        <LinkContainer>
          <TextLink
            onMouseDown={() => this.toggleMobileMenuList()}
          >
            <MenuIcon
              alt="Tili couturière Paris"
              src={this.state.displayMobileMenuList ? closeIcon : menuIcon}
            />
          </TextLink>
        </LinkContainer>
      </StyledRow>
    );
  }

  renderBanner() {
    const {
      context: { isMobile },
      showBannerClacDesDoigts,
      showGiftBanner,
      showBannerMask,
    } = this.props;

    const { displayMobileMenuList } = this.state;
    if (isMobile && displayMobileMenuList) return null;
    if (showBannerClacDesDoigts) {
      return (
        <Banner center>
          <BannerText>
          t’as gagné 10 euros en un claquement de doigt !
            <Icon src={happyIcon} alt="Promo clac des doigts" />
          </BannerText>
        </Banner>
      );
    }
    if (showBannerMask) {
      return (
        <GiftBanner>
          <GiftBannerText>
            Nos couturiers peuvent vous fournir des masques !️
          </GiftBannerText>
          <A>
            <GiftButton navy onClick={() => navigate(routesMap.Masks.url)}>
              Commander
            </GiftButton>
          </A>
        </GiftBanner>
      );
    }
    if (showGiftBanner) {
      return (
        <GiftBanner>
          <GiftBannerText>
            Pour la Saint-Valentin offrez la carte cadeau Tilli ! ✌️
          </GiftBannerText>
          <A href="https://cadeau.tilli.fr" target="_blank" rel="noopener">
            <GiftButton navy>J’offre</GiftButton>
          </A>
        </GiftBanner>
      );
    }
    return null;
  }

  render() {
    if (process.env.GATSBY_BRAND) return null;
    const {
      context: { isMobile },
      customerContext: { customer },
      showBannerClacDesDoigts,
      showGiftBanner,
      showBannerMask,
    } = this.props;

    const { displayMobileMenuList, isLoginPopupOpened } = this.state;
    const hasBanner = showBannerClacDesDoigts || showGiftBanner || showBannerMask;
    return (
      <div>
        <BarContainer
          displayMobileMenuList={displayMobileMenuList}
          hasBanner={hasBanner}
        >
          {!isMobile ? this.renderDesktop(hasBanner) : this.renderMobile()}
          {this.renderBanner()}
        </BarContainer>
        {isMobile && displayMobileMenuList &&
          <MobileMenu
            openLoginPopup={() => this.setIsLoginPopupOpened(true)}
            onClick={() => this.toggleMobileMenuList()}
            isCustomerLoggedIn={!!customer}
            close={() => this.setState({ displayMobileMenuList: false })}
          />}
        {isLoginPopupOpened &&
          <div>
            <PopupLogin closePopup={() => this.setIsLoginPopupOpened(false)} />
          </div>
        }
      </div>
    );
  }
}

MenuBar.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  customerContext: PropTypes.shape({
    setSelectedOrder: PropTypes.func,
    initializeCustomerContext: PropTypes.func,
  }).isRequired,
  isLoginPopupOpened: PropTypes.bool,
  showBannerClacDesDoigts: PropTypes.bool,
  showGiftBanner: PropTypes.bool,
  showBannerMask: PropTypes.bool,
  orderContext: PropTypes.shape({
    getOrderRouteAndEvent: PropTypes.func,
  }).isRequired,
};

MenuBar.defaultProps = {
  isLoginPopupOpened: false,
  showBannerClacDesDoigts: false,
  showGiftBanner: false,
  showBannerMask: true,
};

export default withAppContext(withCustomerContext(withOrderContext(MenuBar)));
