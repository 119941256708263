import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import PasswordForgottenModal from './PasswordForgottenModal';
import withCustomerContext from '../../withCustomerContext';
import { colors, mobileThresholdPixels, Button as ButtonV3 } from '../home/v3/styledComponents';
import routesMap from '../../Routes';
import { isEmailValid, isPhoneValid } from '../../services/checkingFormat';
import callApi from '../../services/api';
import { pushToLayer } from '../../services/googleTagManager';
import OutInSideClick from '../home/v2/OutInSideClick';
import CustomerForm from '../order/CustomerForm';
import customerInputs from '../order/customerInputs';
import FacebookButton from '../order/FacebookButton';

const OpacityContainer = styled.div`
  background: ${colors.opacityFilter};
  overflow: auto;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1050;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  overflow: visible;
  width: 390px;
  margin: 0 auto;
  padding: 25px 50px 35px;

  background-color: ${colors.lightGrey};
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 18px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90vw;
    padding: 22px 12px;
    margin-top: 100px;
    margin-bottom: 25px;
  }
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 26px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 22px;
  }
`;

const LineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: Roboto;
  font-weight: 300;
  font-size: 18px;
  color: ${colors.navy};
  margin-top: 25px;
  margin-bottom: 10px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 275px;
    margin-top: 22px;
    margin-bottom: 7px;
  }
`;

const Line = styled.hr`
  width: 168px;
  height: 0.75px;
  border: 0px;
  background-color: ${colors.navy};
  margin-right: 0px;
  margin-left: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 45%;
  }
`;

const Button = styled(ButtonV3)`
  margin: 0px;
  width: 245px;
  ${({ marginTop }) => marginTop && 'margin-top: 5px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    min-width: unset;
    width: 205px;
  }
`;

const TextLink = styled.p`
  margin: 13px 0px 0px;
  cursor: pointer;
  font-size: 12px;
  line-height: 24px;
  ${({ grey }) => grey && `color: ${colors.lightGrey4};`}
  ${({ left }) => left && `
    margin: 6px 0px 25px;
    align-self: flex-start;
    text-decoration: underline;
  `}
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ left }) => left && `
      margin: 4px 0px 20px;
      width: 275px;
      align-self: center;
    `}
  }
`;

const invalidEmail = 'Adresse non valide';

const getRegisterInputs = () => ({
  firstname: { value: '', name: 'firstname', label: 'Prénom', isRequired: true, size: 'large', isMobileLarge: true },
  lastname: { value: '', name: 'lastname', label: 'Nom', isRequired: true, size: 'large', isMobileLarge: true },
  email: { value: '', name: 'email', label: 'Adresse mail', isRequired: true, size: 'large', isMobileLarge: true },
  errorEmail: { name: 'errorEmail', isActive: false, label: invalidEmail },
  phone: { name: 'phone', label: 'Téléphone', isRequired: true, size: 'large', isMobileLarge: true },
  errorPhone: { name: 'errorPhone', isActive: false, label: 'Numéro de téléphone non valide' },
  password: {
    type: 'password',
    value: '',
    name: 'password',
    label: 'Mot de passe',
    isRequired: true,
    size: 'large',
    isMobileLarge: true,
  },
  passwordConfirmation: {
    type: 'password',
    value: '',
    name: 'passwordConfirmation',
    label: 'Confirmation de mot de passe',
    isRequired: true,
    size: 'large',
    isMobileLarge: true,
  },
});

class PopupLogin extends Component {
  constructor() {
    super();
    this.state = {
      isPasswordForgotten: false,
      isAlreadyCustomer: true,
      loginForm: customerInputs.getLoginInputs(),
      isLoginLoading: false,
      registerForm: getRegisterInputs(),
    };
    this.setFormInputValue = this.setFormInputValue.bind(this);
    this.checkFormInputValue = this.checkFormInputValue.bind(this);
    this.loginWithFacebook = this.loginWithFacebook.bind(this);
    this.isLoginWithoutFacebookDone = this.isLoginWithoutFacebookDone.bind(this);
    this.loginWithoutFacebook = this.loginWithoutFacebook.bind(this);
    this.isRegisterDone = this.isRegisterDone.bind(this);
    this.register = this.register.bind(this);
    this.hidePasswordForgottenModal = this.hidePasswordForgottenModal.bind(this);
  }

  setFormInputValue(target, listName) {
    const { name, value } = target;
    const form = { ...this.state[listName] };
    form[name].value = value;
    this.setState({ [listName]: { ...form } });
  }

  checkFormInputValue(name, listName) {
    const form = { ...this.state[listName] };
    if (name === 'email') {
      const isValid = isEmailValid(form.email.value);
      form.errorEmail.isActive = !isValid;
      form.email.error = !isValid;
      if (listName === 'registerForm' || listName === 'forgottenPasswordForm') form.errorEmail.label = invalidEmail;
    } else if (name === 'phone') {
      const isValid = isPhoneValid(form.phone.value);
      form.errorPhone.isActive = !isValid;
      form.phone.error = !isValid;
    } else if (listName === 'registerForm' && name.includes('password')) {
      const isPasswordSameThanConfirmation = form.password.value === form.passwordConfirmation.value;
      form[name].error = form[name].value === '';
      if (
        ((name === 'password'
          && form.passwordConfirmation.error !== undefined
          && !isPasswordSameThanConfirmation)
        || (name === 'passwordConfirmation'
          && form.password.error !== undefined
          && !isPasswordSameThanConfirmation))
      ) {
        form.passwordConfirmation.error = true;
      } else if (name === 'password' && isPasswordSameThanConfirmation) {
        form.passwordConfirmation.error = form.password.value !== '' ? false : undefined;
      }
    } else {
      form[name].error = !form[name].value;
    }
    this.setState({ [listName]: { ...form } });
  }

  login(callApiBody) {
    callApi('login', 'post', callApiBody)
      .then(({ customer }) => {
        const { customerContext: { setCustomerAndGetOrders }, closePopup } = this.props;
        pushToLayer({ event: 'Login' });
        setCustomerAndGetOrders(customer);
        closePopup();
        navigate(routesMap.MyAccount.url);
      })
      .catch(() => {
        const loginForm = this.state.loginForm;
        loginForm.errorConnection.isActive = true;
        this.setState({ loginForm, isLoginLoading: false });
      });
  }

  loginWithFacebook({ accessToken }) {
    const callApiBody = { fbAccessToken: accessToken };
    this.login(callApiBody);
  }

  isLoginWithoutFacebookDone() {
    const loginForm = { ...this.state.loginForm };
    let hasError = false;
    if (loginForm.email.error !== false) { loginForm.email.error = true; hasError = true; }
    if (loginForm.password.value === '') { loginForm.password.error = true; hasError = true; }
    if (hasError) this.setState({ loginForm: { ...loginForm } });
    return !hasError;
  }

  loginWithoutFacebook() {
    const { loginForm: { email, password } } = this.state;
    if (!this.isLoginWithoutFacebookDone()) return;
    this.setState({ isLoginLoading: true });
    const callApiBody = { email: email.value, password: password.value };
    this.login(callApiBody);
  }

  isRegisterDone() {
    const registerForm = { ...this.state.registerForm };
    let hasError = false;
    Object.values(this.state.registerForm)
      .forEach(({ isRequired, value, name }) => {
        if (isRequired && !value) {
          registerForm[name].error = true;
          hasError = true;
        }
      });
    if (hasError) this.setState({ registerForm: { ...registerForm } });
    if (!hasError) {
      hasError = registerForm.email.error !== false
        || registerForm.passwordConfirmation.error !== false || registerForm.phone.error !== false;
    }
    return !hasError;
  }

  register() {
    const { registerForm: { firstname, lastname, email, phone, password } } = this.state;
    if (!this.isRegisterDone()) return;
    callApi('register', 'post', {
      firstname: firstname.value,
      lastname: lastname.value,
      email: email.value,
      phone: phone.value,
      password: password.value,
    })
      .then(({ customer }) => {
        const { customerContext: { setCustomer }, closePopup } = this.props;
        pushToLayer({ event: 'Register' });
        setCustomer(customer);
        closePopup();
        navigate(routesMap.MyAccount.url);
      })
      .catch(() => {
        const registerForm = { ...this.state.registerForm };
        registerForm.errorEmail.label = 'Cet email a déjà un compte associé';
        registerForm.errorEmail.isActive = true;
        registerForm.email.error = true;
        this.setState({ registerForm: { ...registerForm } });
      });
  }

  hidePasswordForgottenModal(isClickOutside = false) {
    const { closePopup } = this.props;
    if (isClickOutside) {
      closePopup();
    } else {
      this.setState({ isPasswordForgotten: false });
    }
  }

  render() {
    const {
      loginForm, registerForm,
      isAlreadyCustomer, isPasswordForgotten, isLoginLoading,
    } = this.state;
    const { closePopup } = this.props;
    if (isPasswordForgotten) {
      return (
        <PasswordForgottenModal
          hide={this.hidePasswordForgottenModal}
        />
      );
    }
    return (
      <OpacityContainer>
        <OutInSideClick handleClickOutside={closePopup}>
          <Container>
            <Title>{isAlreadyCustomer ? 'Connexion' : 'Inscription'}</Title>
            <FacebookButton
              label={isAlreadyCustomer ? 'Se connecter avec Facebook' : "S'inscrire avec Facebook"}
              loginWithFacebook={({ accessToken }) => this.loginWithFacebook({ accessToken })}
            />
            <LineContainer><Line />ou<Line /></LineContainer>
            <CustomerForm
              inputsList={isAlreadyCustomer ? loginForm : registerForm}
              listName={isAlreadyCustomer ? 'loginForm' : 'registerForm'}
              isLoginLoading={isLoginLoading}
              checkFormInputValue={this.checkFormInputValue}
              setFormInputValue={this.setFormInputValue}
              compact
            />
            {isAlreadyCustomer &&
              <TextLink
                grey left
                onClick={() => this.setState({ isPasswordForgotten: true })}
              >
                Mot de passe oublié
              </TextLink>
            }
            {isAlreadyCustomer &&
              <Button navy onClick={this.loginWithoutFacebook}>Se connecter</Button>
            }
            {!isAlreadyCustomer &&
              <Button navy onClick={this.register}>{"S'inscrire"}</Button>
            }
            <TextLink
              onClick={() => this.setState({ isAlreadyCustomer: !isAlreadyCustomer })}
            >
              {isAlreadyCustomer ? 'Pas encore inscrit ?' : 'Déjà client ?'}
            </TextLink>
          </Container>
        </OutInSideClick>
      </OpacityContainer>
    );
  }
}

PopupLogin.propTypes = {
  closePopup: PropTypes.func.isRequired,
  customerContext: PropTypes.shape({
    setCustomer: PropTypes.func.isRequired,
    setCustomerAndGetOrders: PropTypes.func.isRequired,
  }).isRequired,
};

export default withCustomerContext(PopupLogin);
