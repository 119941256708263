const getLoginInputs = () => ({
  email: { value: '', name: 'email', label: 'Adresse mail', isRequired: true, size: 'large', isMobileLarge: true },
  errorEmail: { name: 'errorEmail', isActive: false, label: 'Adresse non valide' },
  password: {
    type: 'password',
    value: '',
    name: 'password',
    label: 'Mot de passe',
    isRequired: true,
    size: 'medium',
    isMobileLarge: true,
    withSpinner: true,
  },
  errorConnection: { name: 'errorConnection', isActive: false, label: 'Adresse mail ou/et mot de passe incorrect' },
});

export default ({ getLoginInputs });
