import React from 'react';
import CustomerContext from './context/CustomerContext';

export default function withCustomerContext(Component) {
  return function WrapperComponent(props) {
    return (
      <CustomerContext.Consumer>
        {state => <Component {...props} customerContext={state} />}
      </CustomerContext.Consumer>
    );
  };
}
