// eslint-disable-next-line max-len
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmailValid = email => EMAIL_REGEX.test(email);

export function isPhoneValid(phone) {
  if (phone) {
    const formattedPhone = phone.replace(/\./g, '').replace(/ /g, '');
    if (isNaN(Number(formattedPhone))) return false;
    if (formattedPhone.startsWith('+')) {
      if (formattedPhone.startsWith('+33')) return formattedPhone.length === 12;
    } else {
      return formattedPhone.length === 10;
    }
    return true;
  }
  return false;
}
