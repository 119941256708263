import React from 'react';
import styled from 'styled-components';
import { colors } from './home/v3/styledComponents';
import videoWebm from '../assets/lookbook-livre.webm';
import videoMp4 from '../assets/lookbook-livre.mp4';

const VideoContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1060;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Video = styled.video`
  max-width: 100%;
  max-height: 100%;

  width: auto;
  height: auto;
`;

const ScreenSaver = () => (
  <VideoContainer>
    <Video autoPlay mute loop>
      <source
        src={videoWebm}
        type="video/webm"
      />
      <source
        src={videoMp4}
        type="video/mp4"
      />
    </Video>
  </VideoContainer>
);

export default ScreenSaver;
