import { pushOrderStartToGTM } from '../CTALink';
import routesMap from '../../Routes';

export const getServicesList = (orderRoute, restart) => ([
  { text: 'Réserver',
    url: orderRoute,
    onClick: () => pushOrderStartToGTM(orderRoute, 'MenuBarServices', restart),
  },
  { text: 'Comment ça marche', url: routesMap.HowItWorksPage.url },
  { text: 'Notre histoire', url: routesMap.AboutPage.url },
  { text: 'Ils parlent de nous', url: routesMap.PressPage.url },
  { text: 'Nos artisans', url: routesMap.OurArtisansPage.url },
  { text: 'Nos tarifs', url: routesMap.PricingsPage.url },
  { text: 'FAQ', url: routesMap.FAQPage.url },
  { text: 'Devenir Tilliste', url: routesMap.BeTillistePage.url },
  { text: 'Avis de nos clients', url: routesMap.CustomerFeedbacksPage.url },
]);

export const partnersList = [
  { text: 'Tout voir', url: routesMap.CollabPage.url },
  { text: 'Nos événements', url: routesMap.EventsPage.url },
  { text: 'Nos partenaires', url: routesMap.PartnersPage.url },
  { text: 'Devenir partenaire', url: routesMap.WhyBecomePartner.url },
];

export const inspirationsList = [
  { text: 'Mode Durable', url: routesMap.SustainableFashionPage.url },
  { text: 'Lookbook', url: routesMap.LookBookPage.url },
  { text: 'Nos conseils', url: routesMap.LaRubrique.url },
  { text: 'Nos événements', url: routesMap.EventsPage.url },
];
