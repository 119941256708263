import React from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login';
import './FacebookButton.css';

const FacebookButton = ({ label, loginWithFacebook }) => (
  <FacebookLogin
    appId="258420877937846"
    autoLoad={false}
    fields="first_name,last_name,email"
    callback={loginWithFacebook}
    textButton={label}
    cssClass="kep-login-facebook facebook_button"
    isMobile
    disableMobileRedirect
  />
);

FacebookButton.propTypes = {
  label: PropTypes.string.isRequired,
  loginWithFacebook: PropTypes.func.isRequired,
};

export default FacebookButton;
