import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { colors, mobileThresholdPixels, fontSizes, margins, menuBarHeightPixels, mobileMenuBarHeightPixels }
  from './v2/styledComponents';


const Ul = styled.ul`
  display: ${props => props.isDisplayed ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  width: fit-content;
  padding: 20px ${margins.s};
  margin: 40px 0px 0px -14px;
  background-color: ${colors.white};
  color: ${colors.navy};
  z-index: 5;
  text-align: left;
  overflow:hidden;
  list-style-type: none;
  line-height: 32px;
  border-width: thin;
  border-style: solid;
  border-color: ${colors.lightGrey};

  @media (max-width: ${mobileThresholdPixels}) {
    background-color: ${colors.white};
    color: ${colors.navy};
    top: 0px;
    min-height: calc(100vh - ${menuBarHeightPixels});
    width: 100vw;
    margin: 0px;
    padding: 20px 50px;
    border-width: 1px 0px 0px 0px;
    top: ${mobileMenuBarHeightPixels};
    left: 0px;
    right: 0px;
    z-index: 2;
    overflow: scroll;
  }
`;

const Li = styled.li`
  &:hover {
    opacity: 0.7;
  }
`;

const Span = styled.span`
  font-family: Roboto;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    text-align: center;
  }
`;

const DropdownList = ({
  dropdownlist, isDisplayed,
}) => (
  <Ul isDisplayed={isDisplayed}>
    {dropdownlist.map(({ url, onClick, text }) =>
      <Li key={text}>
        {!!url &&
          <Link
            to={url}
            style={{ textDecoration: 'none', color: 'inherit' }}
            onClick={() => (typeof onClick === 'function') && onClick()}
          >
            <Span>{text}</Span>
          </Link>
        }
      </Li>,
    )}
  </Ul>
);

DropdownList.propTypes = {
  dropdownlist: PropTypes.arrayOf(PropTypes.shape({})),
  isDisplayed: PropTypes.bool,
};

DropdownList.defaultProps = {
  dropdownlist: [],
  isDisplayed: false,
};

export default DropdownList;
