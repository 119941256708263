import styled from 'styled-components';

// Constants
export const contentWidth = 1180;
export const mobileThreshold = 1180;
export const mobileThresholdPixels = `${mobileThreshold}px`;
export const smallMobileThresholdPixels = `${mobileThreshold / 2}px`;
export const smallDesktopThresholdPixels = '1300px';
export const menuBarHeight = 80;
export const menuBarHeightWithBanner = 80; // 130;
export const menuBarHeightPixels = `${menuBarHeight}px`;
export const menuBarHeightWithBannerPixels = `${menuBarHeightWithBanner}px`;
export const mobileMenuBarHeight = 45;
export const mobileMenuBarHeightWithBanner = 45; // 130;
export const mobileMenuBarHeightPixels = `${mobileMenuBarHeight}px`;
export const mobileMenuBarHeightWithBannerPixels = `${mobileMenuBarHeightWithBanner}px`;

export const margins = {
  xs: '7px',
  s: '15px',
  m: '30px',
  l: '44px',
  xl: '88px',
};

export const fontSizes = {
  s: '14px',
  m: '18px',
  l: '22px',
  xl: '26px',
  x2l: '36px',
  x3l: '48px',
  x4l: '60px',
};

export const fontWeight = {
  light: '200',
  regular: '400',
  medium: '500',
};

export const colors = {
  navy: '#172c42',
  navyTransparent: 'rgba(23,44,66,0.95)',
  lightGrey: '#F7F7F7',
  linen: '#F0F2F4',
  darkGrey: '#787878',
  lightBlue: '#3B68B0',
  purpleGrey: '#F7F6FC',
  yellow: '#F5C946',
  white: '#FFFFFF',
  error: '#F04F54',
};

// Titles Container

export const Title1 = styled.h1`
  color: ${colors.navy};
  font-weight: bold;
  font-size: ${fontSizes.x3l};
  text-transform: uppercase;
  text-align: center;
  font-family: Libre Baskerville;
  line-height: 1.1;
  margin: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 16px;
  }
`;

export const Title2 = styled.h2`
  color: ${props => props.white ? colors.white : colors.navy};
  ${props => props.yellow && `color: ${colors.yellow}`};
  font-family: ${props => props.roboto ? 'Roboto' : 'Libre Baskerville'};
  font-weight: ${props => props.regular ? fontWeight.regular : 'bold'};
  font-size: ${props => !props.big ? fontSizes.s : fontSizes.x2l};
  text-transform: ${props => props.lowercase ? 'none' : 'uppercase'};
  text-align: center;
  margin: ${props => props.margin ? props.margin : '0px'};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${props => props.margin ? props.margin : `0px 0px ${margins.xs} 0px`};
    ${props => props.big && `font-size: ${fontSizes.m}`};
  }
`;

export const Title2Footer = styled(Title2)`
  font-family: Roboto;
  font-weight: ${fontWeight.regular};
  text-transform: none;
  font-size: ${fontSizes.xl};
  margin: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
  }
`;

export const Title3 = styled.h3`
  color: ${props => props.yellow ? colors.yellow : colors.navy};
  font-family: Libre Baskerville;
  font-weight: ${props => !props.big ? fontWeight.light : 'bold'};
  font-size: ${props => !props.big ? '40px' : fontSizes.x3l};
  text-transform: uppercase;
  margin: 15px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    font-size: ${props => !props.big ? fontSizes.x2l : fontSizes.l};
    margin-bottom: ${margins.s};
  }
`;

export const Subtitle = styled.p`
  color: ${colors.navy};
  font-size: 32px;
  text-align: center;
  font-family: Roboto;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;

export const Subtitle2 = styled.p`
  color: ${colors.navy};
  font-family: Roboto;
  font-size: ${fontSizes.xl};
  font-weight: ${fontWeight.medium};
  margin: ${margins.s} 0px 0px;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${props => props.muchText ? '24px' : fontSizes.l};
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;

export const EmphText = styled.p`
  color: ${colors.navy};
  font-size: ${fontSizes.xl};
  font-family: Roboto;
  margin: 0px 0px ${margins.s} 0px;
  ${props => props.center && 'text-align: center'};

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    font-size: 23px;
  }
`;

// // // Globals Container
// div

export const RelativePosition = styled.div`
  position: relative;
  ${props => props.center && 'text-align: center'};
`;

export const AbsolutePosition = styled.div`
  position: absolute;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  ${props => props.justifyContent && `justify-content: ${props.justifyContent}`};
  ${props => props.alignItems && `align-items: ${props.alignItems}`};
  ${props => props.alignContent && `align-content: ${props.alignContent}`};
  ${props => props.margin && `margin: ${props.margin}`};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  ${props => props.justifyContent && `justify-content: ${props.justifyContent}`};
  ${props => props.alignItems && `align-items: ${props.alignItems}`};
  ${props => props.alignContent && `align-content: ${props.alignContent}`};
  ${props => props.margin && `margin: ${props.margin}`};
`;

export const Content = styled.div`
  font-family: Roboto;
  background-color: ${props => props.navy ? colors.navy : colors.white};
  ${props => props.lightGrey && `background-color: ${colors.lightGrey}`};
  width: ${props => props.full ? '100vw' : `${contentWidth}px`};
  margin: auto;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100vw;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  background-color: ${colors.white};
  padding-top: ${({ hasNoBanner }) => hasNoBanner
    ? menuBarHeightPixels
    : menuBarHeightWithBannerPixels};
  font-size: ${fontSizes.m};
  font-family: Roboto;

  @media (max-width: ${mobileThresholdPixels}) {
    padding-top: ${({ hasNoBanner }) => hasNoBanner
    ? mobileMenuBarHeightPixels
    : mobileMenuBarHeightWithBannerPixels};
  }
`;

// Footer main container
export const ContainerFooter = styled.div`
  padding: ${margins.l} 0;
  background-color: ${props => props.white ? colors.white : colors.navy};
  text-align: center;
  width: 100%;

  @media (max-width: ${mobileThresholdPixels}) {
    padding-bottom: ${margins.s};
  }
`;

// Home blocks (whyTilli, howItWorks, partnerPage, theyLoveTilli)  container
export const ContainerBlock = styled.div`
  margin-top: ${margins.xl};
  ${props => props.withMargin && `margin: ${margins.xl} ${margins.l} 0px;`}
  color: ${colors.navy};
  ${props => props.lightGrey && `background-color: ${colors.lightGrey}`};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.l} 0px 0px;
    padding: 0px ${margins.s};
  }
`;

export const ButtonContainer = styled.div`
  margin: ${props => props.margin ? props.margin : `${margins.l} 0px 0px 0px`};

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    margin: ${props => props.margin ? props.margin : `${margins.m} 0px 0px 0px`};
  }
`;

export const ClothType = styled.div`
  box-sizing: border-box;
  flex-basis: auto;
  padding: 14px 0px;
  width: 49%;
  border: 1px solid ${colors.navy};
  color: ${props => props.isSelected ? colors.white : colors.navy};
  ${props => props.isSelected ? `background: ${colors.navy}` : ''};
  cursor: pointer;
  transition: all 0.3s ease;

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      transform: scale(1.05);
    }
  }

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    padding: 10px 0px;
  }
`;

// Images and icons
export const Img = styled.img`
  position: relative;
  width: 100%;
`;

export const ImgCover = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

export const Icon = styled.img`
  height: ${props => !props.big ? '55px' : '80px'};
  width: ${props => !props.big ? '55px' : '80px'};
  margin: ${props => props.margin ? props.margin : '20px'};
  ${props => props.noMarginLeft && 'margin-left: 0px'};
  ${props => props.framed ? `
    margin: 0px;
    padding: 20px;
    height: 40px;
    width: 40px;
    fill: ${colors.navy};
  ` : ''}

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover{
      transform: scale(1.1) rotate(-7deg);
    }
  }

  @media (max-width: ${mobileThresholdPixels}) {
    height: 60px;
    width: 60px;
    ${props => props.framed ? `
      padding: 16px;
      height: 27px;
      width: 27px;
    ` : ''}
  }
`;

// Buttons
export const Button = styled.button`
  min-height: 56px;
  min-width: 210px;
  padding: 3px 20px 0px;

  color: ${props => props.navy ? colors.white : colors.navy};
  background-color: ${props => props.navy ? colors.navy : colors.white};
  ${props => props.transparent && 'background-color: rgba(0, 0, 0, 0)'};

  border-width: ${props => props.navy ? 'inherit' : 'medium'};
  ${props => props.noBorder && 'border-width: 0px'};
  border-color: ${colors.navy};
  border-style: solid;

  font-family: Roboto;
  font-size: ${props => props.large ? fontSizes.l : fontSizes.s};
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;

  transition: all 0.3s ease;
  outline: none;

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      transform: scale(1.1);
    }
  }

  @media (max-width: ${mobileThresholdPixels}) {
    min-width: 180px;
    min-height: 50px;
    margin: ${props => props.margin ? props.margin : margins.s};
    padding: ${margins.s};
    font-size: ${fontSizes.s};
    letter-spacing: 1px;
    border-width: ${props => props.navy ? 'inherit' : '1.5px'};
    ${props => props.noBorder && 'border-width: 0px'};
  }
`;

// Links and paragraphs

export const A = styled.a`
  padding: ${margins.m} 0px;
  align-self: center;
  color: ${colors.navy};
  font-size: ${fontSizes.l};
  font-weight: 300;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

export const OrderStepsA = styled.a`
  font-family: Roboto;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  font-size: ${fontSizes.s};
  color: ${colors.navy};
  padding: ${margins.s} ${margins.s} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
  }
`;

export const P = styled.p`
  color: ${colors.navy};
  font-family: Roboto;
  font-size: ${fontSizes.xl};
  margin: ${margins.l} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    margin: 0px;
  }
`;

export const OrderSummaryText = styled.p`
  font-family: Roboto;
  font-size: 12px;
  color: ${props => props.error ? colors.error : colors.navy};
`;

export const ErrorText = styled.p`
  font-family: Roboto;
  text-align: center;
  font-size: ${fontSizes.s};
  color: ${colors.error};
`;

export const SpanPre = styled.span`white-space: pre;`;

export const ColoredSpan = styled.span`
  color: ${colors.navy};
  ${props => props.color === 'white' && `color: ${colors.white};`}
  ${props => props.color === 'yellow' && `color: ${colors.yellow};`}
`;

export const BoldSpan = styled.span`font-weight: ${props => props.fontWeight}`;

// Inputs & labels

export const Input = styled.input`
  background-color: ${colors.white};
  border-color: ${colors.navy};
  border-width: thin;
  ${props => !props.inline ? 'width: 100%;' : ''};
  ${props => props.width ? `width: ${props.width};` : ''};
  padding: ${margins.s};
  ${props => !props.inline ? `margin: ${margins.s} 0px;` : `margin: 0px ${margins.s};`};
  ${props => props.inline ? 'display: inline;' : ''};
  box-sizing: border-box;
`;

// TODO: remove magic constant "3px" 2 lines below
export const FormInput = styled.input`
  ${props => props.full ?
    `width: calc(100% - ${margins.s} - 3px)` :
    `width: calc(243px - ${margins.s});`
};
  height: 40px;
  margin: ${props => props.noMargin ? '0px' : `${margins.s} 0px 0px`};
  padding-left: ${margins.s};
  border: solid 1px ${colors.navy};
  color: ${props => props.error ? colors.error : colors.navy};

  &::placeholder {
    color: ${props => props.error ? colors.error : colors.navy};
    opacity: 0.6;
  }

  &:disabled {
    background-color: ${colors.lightGrey};
    color: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: ${mobileThresholdPixels}) {
    ${props => props.full ?
    `width: calc(100% - ${margins.s} - 3px)` :
    `width: calc(50% - ${margins.s} - 10.5px);`
};
  }
`;

export const Label = styled.label`
  color: ${colors.navy};
  font-size: ${props => props.small ? fontSizes.m : fontSizes.l};
  font-family: Roboto;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;

// Elements of design
export const LineH = styled.div`
  background-color: ${colors.navy};
  border: 1px solid ${colors.navy};
  width: ${props => !props.width ? '100%' : props.width};
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => !props.focus && 'border-bottom-width: 0px'};
`;

export const LineV = styled.div`
  background-color: ${({ color }) => color || colors.navy};
  min-width: 1px;
  width: 1px;
  height: ${props => !props.height ? '100%' : props.height};
  ${props => props.margin && `margin: ${props.margin}`};
`;

// order Elements
export const Categories = styled.section`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
`;

export const Category = styled.article`
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.3s ease;
  margin-bottom: 5px;
  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      opacity: 1;
    }
  }
`;

export const Header3 = styled.header``;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: ${fontSizes.s};
  font-weight: 500;
  color: ${colors.navy};
  border: 1px solid ${colors.navy};
  border-width: 2px 0px 1px;
  margin: 0px 0px ${margins.m} 0px;
  padding: ${margins.s} 0px calc(${margins.s} - 2px);
`;

export const HeaderPhotoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px calc((50vw - ${contentWidth / 2}px));
  width: ${contentWidth}px;
  height: 70vh;
  ${props => props.backgroundImage && `
    background: url(${props.backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
  `}

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100vw;
    align-items: flex-start;
    justify-content: flex-end;
  }
`;

export const YellowLineH = styled.div`
  width: 72px;
  margin: ${margins.s} 0px;
  border: 1px solid ${colors.yellow};
  ${props => props.focus && `
    border-width: 2px;
    width: 90%;
  `}
`;

export const Dot = styled.div`
  width: fit-content;
  margin: ${margins.s} 0px;
  border: 2px solid ${colors.navy};
  border-width: 3px;
  border-radius: 100%;
`;
